.NavBar {
    background-color: #fff;
    box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 20%);
}

.NavBar__img {
    width: 215px;
}

.navLink {
    color: #2db035 !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.navLink:hover {
    color: #448422 !important;
}