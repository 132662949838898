.control {
    margin-bottom: 1rem;
}

.control label {
    display: block;
    color: #262626;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: .25rem;
}

.control input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.control input:active,
.control input:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid #262626;
}

.actions {
    margin-bottom: 0;
}

.actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #41A928;
    border: 1px solid #41A928;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
}

.actions button:hover {
    background-color: #448422;
    border-color: #448422;
}

.actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: #2db035;
    border: none;
    padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
    background-color: transparent;
    color: #448422;
}

.errorText {
    font-size: 14px;
    color: #e1592a;
    font-weight: 500;
    margin-bottom: 0;
}